import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import parse from 'html-react-parser';

const Technologies = () => {
  const data = useStaticQuery(graphql`
    query TechnologiesPage {
      allWpPage(filter: {uri: {eq: "/technologies/"}}) {
        nodes {
          title
          technologiesPage {
            technologiesDescription
            wordpressGroup {
              wordpressDescription
              wordpressLogo
            }
            gatsbyjsGroup {
              gatsbyjsDescription
              gatsbyjsLogo
            }
            wordpressAndGatsbyjsGroup {
              wordpressAndGatsbyjsDescription
            }
          }
        }
      }
    }
  `);
  
  let technologiesData = data.allWpPage.nodes[0];

  let pageTitle = technologiesData.title;

  let technologiesPageData = technologiesData.technologiesPage;
  let technologiesDescription = technologiesPageData.technologiesDescription;

  let wordpressGroupData = technologiesPageData.wordpressGroup;

  let gatsbyjsGroupData = technologiesPageData.gatsbyjsGroup;

  let wordpressAndGatsbyjsData = technologiesPageData.wordpressAndGatsbyjsGroup;

  let wordpressGroupDescription = wordpressGroupData.wordpressDescription;
  let wordpressGroupLogo = wordpressGroupData.wordpressLogo;

  let gatsbyjsGroupDescription = gatsbyjsGroupData.gatsbyjsDescription;
  let gatsbyjsGroupLogo = gatsbyjsGroupData.gatsbyjsLogo;

  let wordpressAndGatsbyDataDescription = wordpressAndGatsbyjsData.wordpressAndGatsbyjsDescription;

  return (
    <Layout>
      <Seo title="Technologies"/>
      <section className="container technologies-page page-default" >
        <div className="text-center text-lg-start">
          <h1 className="display-2">{ pageTitle }</h1>
        </div>
        <div className="technologies-page-description fs-5">
          { technologiesDescription }
        </div>

        <h2 className="display-4 display-lg-3 text-center text-lg-start pt-5">WordPress</h2>
        <div className="wordpress-group d-flex flex-column-reverse flex-lg-row justify-content-lg-between align-items-center flex-column flex-lg-row">
          <div className="wordpress-description">
            { parse(wordpressGroupDescription) }
          </div>
          <div className="d-flex justify-content-center"> 
            <img src={wordpressGroupLogo} className="wordpress-image" alt="wordpress"/>
          </div>
        </div>      
      
        <h2 className="display-4 display-lg-3 text-center text-lg-start pt-5">Gatsby.js</h2>
        <section className="gatsbyjs-group d-flex flex-column-reverse flex-lg-row justify-content-lg-between align-items-center flex-column flex-lg-row" id="gatsbyjs-group">
          <div className="gatsbyjs-description" id="gatsby-description">
            { parse(gatsbyjsGroupDescription) }
          </div>
          <div className="d-flex justify-content-center"> 
            <img src={gatsbyjsGroupLogo} className="gatsbyjs-image" alt="gatsbyjs"/>
          </div>
        </section>
      
        <h2 className="display-4 display-lg-3 text-center text-lg-start pt-5">WordPress + Gatsby.js</h2>
        <section className="wordpress-gatsbyjs-group d-flex flex-column-reverse flex-lg-row justify-content-lg-between align-items-center flex-column flex-lg-row">
          <div className="wordpress-gatsbyjs-description">
            { parse(wordpressAndGatsbyDataDescription) }
          </div>
        </section>
      </section>
    </Layout>
  );
}

export default Technologies;